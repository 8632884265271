.ts-provider-search-header {
    background-image: url("./res/search-bg.png") url("./res/therapist_search.webp");
}

.ts-therapist-search-image {
    position: relative;
    width: 250px;
    height: 300px;
    top: -50px;
    right: 50px;
}

#ts-google-maps div {
    &:first-child {
        background: none !important;
    }
}

.ts-provider-results-list {
    max-width: 100%;
    height: 100%;
    z-index: 99;

    @media (min-width: 1200px) {
        max-width: 600px;
        min-width: 600px;
        height: 90%;
    }
}

.ts-provider-results-map {
    max-width: 100%;
    height: 0%;
    overflow: scroll;

    @media (min-width: 1200px) {
        max-width: 600px;
        min-width: 600px;
        height: 90%;
        overflow: scroll;
    }
}

.ts-clinic-carousel {
    display: flex ;
    align-items: center;
    gap: 0.75rem;

    .slick-list {
        flex: 1;
        // NOTE: ensures content remains visible at all screen sizes, setting
        // minimum width to 1px allows the flex container to properly calculate
        // space distribution while preventing content overflow
        width: 1px;
    }
}

.pac-container {
    z-index: 9999;
}
